<template>
  <div class="content">
    <div id="page_title">
      <span>工单列表</span>
<!--      <img src="../assets/images/list/center.png" alt @click="toCenter">-->
    </div>
    <!-- tab切换 -->
    <Tab :data="tabData" :nums="num" @on-click="dealClick" />
    <div ref="container" class="container">
      <div
        v-for="(item,idx) in list"
        :key="idx"
        @click="toDetail(item.work_order_id,item.work_order_type)"
      >
        <div class="cont_top">
          <img src="../assets/images/list/list-icon.png" alt>
          <span>{{ item.work_order_no }}</span>
          <span class="fr">{{ item.work_order_status }}</span>
        </div>
        <div class="cont_bottom">
          <p>车牌号：{{ item.license_plate }}</p>
          <p>工单类型：{{ item.work_order_type_name }}</p>
          <p>分配时间：{{ item.distribution_time }}</p>
        </div>
        <img
          v-if="item.is_finish==1"
          class="not-perfect"
          src="../assets/images/list/not-perfect.png"
          alt
        >
      </div>
      <img v-if="isShowImg" class="noData" src="../assets/images/list/noData.png" alt="">
    </div>
    <!--显示加载中状态-->
    <Loading :no-more="noMore" :loading="loading" />
  </div>
</template>

<script>
import port from '@/api/port'
import cookies from '@/utils/cookie.js'
import Loading from '@/components/loading/loading.vue'

export default {
  name: 'Home',
  components: {
    Loading
  },
  data() {
    return {
      msg: this.$route.query.msg, // 提示信息
      list: [], // 工单列表
      num: {}, // 数字标识
      tabData: [
        { name: '全部', value: 1 },
        { name: '待诊断', value: 2 },
        { name: '待检验', value: 4 },
        { name: '已完成', value: 5 }
      ],
      isShowImg: false,
      page: 1,
      loading: false, // 是否开启分页
      noMore: false, // 是否显示到底
      token: cookies.get('token'),
      params: {
        method: '200000',
        type: 1,
        token: cookies.get('token')
      }
    }
  },
  created() {
    const _this = this
    // this.roleModalShow = false;
    if (this.msg) {
      this.$toast({ message: this.msg })
    }
    console.log(this.token)
    if (this.token) {
      this.request()
    } else {
      this.$router.replace({
        path: '/login'
      })
    }
  },
  mounted() {
    this.$scroll(this.$refs.container, () => {
      !this.loading && this.loadMore()
    })
  },
  methods: {
    // tab切换
    dealClick(item, index) {
      this.request(item.value)
    },
    // 列表请求
    request(type) {
      const _this = this
      this.params.type = type || 1
      port.portAddress(this.params).then(res => {
        if (res.status == 200) {
          _this.list = res.data.order
          _this.num = res.data.num
          if (!res.data.order) {
            _this.isShowImg = true
          } else {
            _this.isShowImg = false
          }
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    // 加载更多
    loadMore() {
      // var _this = this;
      // if (_this.loading) return; //阻止下次请求（false可以进行请求）；
      // if (_this.noMore) return; //阻止结束当前请求（false可以进行请求）；
      // _this.loading = true;
      // _this.page++;
      // let data = {
      //   method: "100000",
      //   params: {
      //     page: _this.page,
      //     type_id: ""
      //   }
      // };
      // port.portAddress(data).then(res => {
      //   if (res.data.length > 0) {
      //     this.loading = false;
      //     this.list = [...this.list, ...res.data];
      //   } else {
      //     this.loading = false;
      //     this.noMore = true;
      //   }
      // });
    },
    toDetail(id, type) {
      if (type == 1) {
        this.$router.push({
          path: '/detail',
          query: {
            id: id
          }
        })
      } else {
        this.$router.push({
          path: '/reworkingDetail',
          query: {
            id: id
          }
        })
      }
    },
    toCenter() {
      this.$router.push({
        path: '/personal'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  min-height: 100vh;
  position: relative;
  #page_title {
    position: fixed;
    width: 100%;
    top: 0;
    height: 0.75rem;
    line-height: 0.75rem;
    background-color: #fff;
    text-align: center;
    z-index: 10;
    img {
      position: fixed;
      width: 0.41rem;
      vertical-align: middle;
      top: 0.15rem;
      right: 0.48rem;
    }
    span {
      vertical-align: middle;
      color: #333;
      font-size: 0.31rem;
    }
  }
  .container {
    padding-top: 1.6rem;
    overflow-y: auto;
    .noData{
      display: block;
      width:2rem;
      margin:0 auto;
      margin-top:2rem;
    }
    & > div {
      padding: 0.22rem 0.2rem 0.27rem 0.2rem;
      box-sizing: border-box;
      margin: 0.14rem 0.2rem;
      height: 2.58rem;
      background-color: #fff;
      box-shadow: 0px 0px 0.09rem 0 rgba(243, 243, 243, 1);
      border-radius: 0.14rem;
      position: relative;
      .not-perfect {
        position: absolute;
        top: 1.18rem;
        right: 0.29rem;
        width: 1.21rem;
        height: 1.19rem;
      }
    }
    .cont_top {
      padding-bottom: 0.22rem;
      border-bottom: 0.01rem solid #f3f3f3;
    }
    .cont_top img {
      width: 0.34rem;
      margin-right: 0.03rem;
      vertical-align: middle;
    }
    .cont_top span {
      color: #999999;
      font-size: 0.27rem;
      vertical-align: middle;
    }
    .cont_top .fr {
      color: #004a8f;
      font-size: 0.2rem;
      vertical-align: middle;
    }
    .cont_bottom {
      padding-top: 0.27rem;
      p {
        font-size: 0.24rem;
        color: #333333;
        margin-bottom: 0.1rem;
      }
    }
  }
  // .personal{
  //   position: absolute;
  //   right: 0.2rem;
  //   bottom:0.5rem;
  //   width:1rem;
  //   height: 1rem;
  //   line-height: 1rem;
  //   text-align: center;
  //   border:0.01rem solid #333;
  //   border-radius: 50%;
  // }
}
</style>
