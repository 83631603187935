<template>
  <div>
    <div v-if="loading" class="loading-box"><mt-spinner type="fading-circle" class="loading-more" color="#0188fd" /></div>
    <div v-if="noMore" class="no-more">——亲，已经到底了哦！——</div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    noMore: Boolean,
    loading: Boolean
  }
}
</script>

<style>
  .no-more {
    text-align: center;
  }
  .mint-spinner-fading-circle {
    margin: 0 auto;
  }
</style>
